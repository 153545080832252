import type { EventGA } from "@netgame/utils/src/dispatchGAEvent";
import { toast } from "vue3-toastify";

import useAppInitData from "./useAppInitData";

const useCaptcha = () => {
	const { t } = useT();
	const { data: appInit } = useAppInitData();
	const captchaIsEnabled = computed(() => appInit.value?.captcha?.isEnabled);

	const getCaptchaToken = async () => {
		if (!window.grecaptcha || !captchaIsEnabled.value) {
			return;
		}

		const captchaKey = computed(() => appInit?.value?.captcha?.key);
		const captchaToken = await window.grecaptcha.execute(captchaKey.value);

		return captchaToken || "";
	};

	const captchaError = (payload: { event: EventGA } & Record<string, string | number>) => {
		const { isDesktop } = useDevice();

		dispatchGAEvent(payload);

		toast.error(t("captcha_error_message"), {
			theme: toast.THEME.DARK,
			position: isDesktop ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		});
	};

	return {
		captchaIsEnabled,
		getCaptchaToken,
		captchaError
	};
};

export default useCaptcha;
